import { formatInTimeZone } from "date-fns-tz";
import { formatRawAsISO } from "../../utils/datetime";
import { FixtureKOTime } from "./styles";
import { IFixtureTime } from "./types";

const FixtureTime = ({ fixture }: IFixtureTime) => {
  if (fixture.provisional_start_time) {
    return <FixtureKOTime>TBC</FixtureKOTime>;
  }

  if (fixture.kickoff_time) {
    return (
      <FixtureKOTime as="time" dateTime={formatRawAsISO(fixture.kickoff_time)}>
        {formatInTimeZone(
          fixture.kickoffDate,
          Intl.DateTimeFormat().resolvedOptions().timeZone,
          "HH:mm",
        )}
      </FixtureKOTime>
    );
  }

  return <FixtureKOTime>-</FixtureKOTime>;
};

export default FixtureTime;

import styled from "styled-components";

export const FixtureKOTime = styled.span`
  flex: 1 0 6.6rem;
  display: inline-block;
  padding: 0.9rem ${({ theme }) => theme.space[2]};
  border: 1px solid #e5e5e5;
  line-height: 1.1;
  text-align: center;

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    padding: 0.8rem 1.6rem;
    font-size: ${({ theme }) => theme.fontSizes[1]};
  }
`;
